import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CheckMark } from './../../images/Icons/checkmark.svg';
import style from './checkbox.module.scss';

const Checkbox = (props) => {
    const {
        checked,
        value,
        name,
        inputProps,
        onChange,
        error
    } = props;

    return (
        <span className={[style.checkboxContainer, (error) ? style.error : null].join(' ')}>
        <input
            type="checkbox"
            value={value}
            name={name}
            checked={checked}
            onChange={onChange}
            {...inputProps}
        />
        <div className={[
            style.checkbox,
            (checked) ? style.checked : null,
        ].join(' ')}
        >
            <CheckMark className={style.checkmark} width="12px" height="12px" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" />
        </div>
    </span>
    );
}

Checkbox.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.string,
    name: PropTypes.string,
    inputProps: PropTypes.object,
};

export default Checkbox;
