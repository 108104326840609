import React from 'react';
import Button from './../buttons';
import style from './uploadfield.module.scss';
import Close from './../../images/close.svg';

const Uploadfield = ({
    accept,
    id,
    onChange,
    onReset,
    name,
    value,
    buttonLabel,
    label,
    error
}) => (
    <>
        {label &&
        <label htmlFor={id} className={[style.label, (error) ? style.error : null].join(' ')}>{label}</label>
        }
        <div className={style.row}>
            <input
                autoComplete="off"
                accept={(accept) ? accept : ".jpg, .pdf, .doc"}
                name={name}
                className={style.inputUpload}
                id={id}
                multiple={false}
                type="file"
                onChange={onChange}
            />
            <label htmlFor={id}>
                <Button type="span" tertiary={true} className={style.button}>{buttonLabel}</Button>
            </label>
            <span className={style.value}>
                {(value) ? value.name : ''}
                {(value) &&
                <button onClick={onReset} className={style.buttonRemove}><img src={Close} alt="Close" /></button>
                }
            </span>
        </div>
        {error &&
        <span className={style.errorMessage}>{error}</span>
        }
    </>
);

export default Uploadfield;
