import React from 'react';
import style from './linkList.module.scss';

const linkList = ({
    className,
    defaultActive,
    children: childrenProp,
    }) => {
    let childIndex = 0;
    const children = React.Children.map(childrenProp, child => {
        childIndex += 1;
        const defaultSelectedStyle = (childIndex === 1 && defaultActive) ? style.selected : null;
        return React.cloneElement(child, {
            className: [child.className, style.link, defaultSelectedStyle].join(' '),
            activeClassName: [child.activeClassName, style.selected].join(' '),
        });
    });

    return (
        <div className={[style.linkList, className].join(' ')}>
            {children}
        </div>
    );
};

export default linkList;
