import React from 'react';
import RadioGroupContext from './../radioGroup/radioGroupContext';
import style from './radio.module.scss';

const Radio = (props) => {
    const {
        checked: checkedProp,
        name: nameProp,
        onChange: onChangeProp,
        ...rest
    } = props;

    let checked = checkedProp;
    let name = nameProp;
    const radioGroup = React.useContext(RadioGroupContext);
    const onChange = (radioGroup && radioGroup.onChange) ? radioGroup.onChange : onChangeProp;

    if (radioGroup) {
        if (typeof checked === 'undefined') {
            checked = radioGroup.value === props.value;
        }
        if (typeof name === 'undefined') {
            name = radioGroup.name;
        }
    }
    return (
        <span className={style.radioContainer}>
            <div
                className={[
                    style.radio,
                    (checked) ? style.checked : null,
                ].join(' ')}
            />
            <input
                type="radio"
                name={name}
                checked={checked}
                onChange={onChange}
                {...rest}
            />
        </span>
    );
};

export default Radio;
