import React, { useCallback, useRef, useState } from 'react';
import { Link } from "gatsby";
import { animated, useTransition } from 'react-spring'
import { useEventListener } from '../../hooks';
import LinkList from './../../components/linkList/';
import Wrapper from './../../components/wrapper/';
import WrapperContent from './../../components/wrapperContent/';
import style from './aboutustabs.module.scss';

const RenderLinkList = ({ defaultTabActive, tabs }) => (
    <LinkList className={style.aboutUsTabs} defaultActive={defaultTabActive}>
        {tabs.map((tab, index) => {
            return (
                <Link key={`link-${index}`} partiallyActive={true}
                      to={tab.fields.slug}>{tab.frontmatter.tabLabel}</Link>
            );
        })}
    </LinkList>
);

const AboutUsTabs = ({ defaultTabActive, tabs }) => {
    const containerEl = useRef();
    const [sticky, setSticky] = useState(false);
    const transitions = useTransition(sticky, null, {
        from: { transform: 'translateY(-100%)' },
        enter: { transform: 'translateY(0)' },
        leave: { transform: 'translateY(-100%' },
    });
    const onScrollHandler = useCallback((props) => {
        const posTop = (containerEl.current.getBoundingClientRect().top + props.target.documentElement.scrollTop);
        const posBottom = posTop + containerEl.current.getBoundingClientRect().height;

        if (props.target.documentElement.scrollTop > (posBottom - 82) && sticky === false) {
            setSticky(true);
        } else if (props.target.documentElement.scrollTop < (posBottom - 82) && sticky === true) {
            setSticky(false);
        }

    }, [sticky, setSticky]);

    // we do need the document object. Since this ain't available on ssr the build will fail.
    useEventListener('scroll', onScrollHandler, (typeof document !== 'undefined') ? document : null);

    if (!tabs) return null;

    return (
        <div ref={containerEl}>
            <RenderLinkList defaultTabActive={defaultTabActive} tabs={tabs} />
            {
            transitions.map(({ item, key, props }) =>
            item && <animated.div key={key} style={props} className={style.sticky}>
                    <Wrapper>
                        <WrapperContent overflow={false} className={style.stickyNav}>
                            <RenderLinkList defaultTabActive={defaultTabActive} tabs={tabs} />
                        </WrapperContent>
                    </Wrapper>
                </animated.div>
            )
            }
        </div>
    );
};

export default AboutUsTabs;
