import React from 'react';
import style from './formControlLabel.module.scss';

const FormControlLabel = (props) => {
    const {label, value, onChange, control, className, error, ...rest } = props;
    const controlProps = {};
    ['checked', 'name', 'onChange', 'value', 'inputRef', 'error'].forEach(key => {
        if (typeof control.props[key] === 'undefined' && typeof props[key] !== 'undefined') {
            controlProps[key] = props[key];
        }
    });

    return (
        <>
            <label className={[style.formControlLabel,  (error) ? style.error : null, className].join(' ')} {...rest}>
                {React.cloneElement(control, controlProps)}
                {label}
            </label>
            {error &&
            <span className={style.errorMessage}>{error}</span>
            }
        </>
    );
};

export default FormControlLabel;
