import React from 'react';
import RadioGroupContext from './radioGroupContext';


const RadioGroup = (props) => {
    const { children, name, value: valueProp, onChange, ...rest} = props;
    const { current: isControlled } = React.useRef(valueProp != null);
    const [ valueState, setValue ] = React.useState(() => {
        if (!isControlled) {
            return props.defaultValue;
        }
        return null;
    });
    const value = isControlled ? valueProp : valueState;
    const handleChange = event => {
        if (!isControlled) {
            setValue(event.target.value);
        }
        if (onChange) {
            onChange(event, event.target.value);
        }
    };
    const context = { name, onChange: handleChange, value };

    return (
        <div {...rest}>
            <RadioGroupContext.Provider value={context}>
                {children}
            </RadioGroupContext.Provider>
        </div>
    );
};

export default RadioGroup;
