import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { LanguageLayout } from '../containers/layouts';
import Wrapper from './../components/wrapper/';
import WrapperContent from './../components/wrapperContent/';
import PageIntro from './../components/pageIntro/';
import Content from './../components/content/';
import AboutUsTabs from '../containers/aboutUsTabs';
import ContactTab from './../containers/contactTab';
import NewsletterForm from './../containers/newsletterForm';
import Member from './../components/member/';

import style from './aboutus-page.module.scss';

export const AboutPageTemplate = ({ title, defaultTabActive, content, tabs, activeTab, activeTabType }) => {
    return (
        <>
            <PageIntro color="thinair" size="small">
                <Wrapper>
                    <WrapperContent>
                        <PageIntro.title>{title}</PageIntro.title>
                    </WrapperContent>
                </Wrapper>
            </PageIntro>
            <Wrapper>
                <WrapperContent overflow={false}>
                    <AboutUsTabs
                        tabs={tabs}
                        defaultTabActive={defaultTabActive}
                    />
                    {content}
                </WrapperContent>
                {activeTabType !== 'contact' &&
                <WrapperContent color="blue">
                    <NewsletterForm />
                </WrapperContent>
                }
                {(activeTabType === 'contact' && !defaultTabActive) &&
                    <ContactTab data={activeTab} />
                }
            </Wrapper>
        </>
    )
}

AboutPageTemplate.propTypes = {
    title: PropTypes.string,
    heading: PropTypes.string,
    langKey: PropTypes.string,
    content: PropTypes.node,
};

const TabHead = ({ title, body }) => (
    <>
        <h2 className={style.headingTwo}>{title}</h2>
        <Content html={body} />
    </>
);

const TabContainer = ({ data }) => (
    <>
        <TabHead title={data.frontmatter.title} body={data.fields.frontmattermd.bodyText.html} />
        <TabContent data={data} />
    </>
);

const TabContent = ({ data }) => {
    if (!data)  return null;

    switch(data.frontmatter.tabType) {
        case 'team':
            if (!data.frontmatter.teamMembers) return null;
            return (
                <div>
                    {data.frontmatter.teamMembers.map((member, index) => {
                        return (
                            <Member
                                key={`member-${index}`}
                                name={member.name}
                                image={member.image.childImageSharp.fixed}
                                memberFunction={member.function}
                                shortText={member.shortText}
                                longText={member.longText}
                            />
                        )
                    })}
                </div>
            );
        case 'contact':
        case 'text':
        default:
            return null;
    }
};

const AboutPage = (props) => {
    const { frontmatter, fields } = props.data.page;
    const activeTab = (props.data.activeTab) ? props.data.activeTab : (props.data.defaultTab) ? props.data.defaultTab : null;
    const tabs = (props.data.tabs) ? props.data.tabs.nodes : null;

    return (
        <LanguageLayout location={props.location}>
            <AboutPageTemplate
                title={frontmatter.title}
                heading={frontmatter.heading}
                defaultTabActive={(fields.slug === props.pageContext.slug) ? true : false}
                tabs={tabs}
                activeTabType={activeTab.frontmatter.tabType}
                activeTab={activeTab}
                content={<TabContainer data={activeTab} />}
            />
        </LanguageLayout>
    );
};

AboutPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    }),
};

export default AboutPage

export const pageQuery = graphql`
  fragment TabInformation on MarkdownRemark {
    frontmatter {
      title
      bodyText
      tabType
      teamMembers {
        name
        function
        image {
            publicURL
            childImageSharp {
              fixed(width: 184, height: 184) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
        }
        shortText
        longText
      }
      address {
        contactName
        contactStreet
        contactZipcode
        contactCity
        contactCountry
        contactEmail
        contactPhone
      }
    }
    fields {
        frontmattermd {
            bodyText {
                html
            }
        }
    }
  }
  
  query AboutPageTemplate($langKey: String!, $slug:String!) {
    page: markdownRemark(
        fields: { langKey: {eq: $langKey }}
        frontmatter: { templateKey: { eq: "aboutus-page" } }
    ) {
      frontmatter {
        title
      }
      fields {
        slug
      }
    }
    tabs: allMarkdownRemark(
        filter: {
            frontmatter: { tab:{ eq: true}, templateKey: { eq:"aboutus-page"}}
            fields: { langKey: {eq: $langKey }}
        }
        sort:{
            fields:[frontmatter___tabOrder]
            order:ASC
        }
    ){
        nodes {
            fields {
                slug
            }
            frontmatter {
                title
                tabLabel
            }
        }
    }
    defaultTab: markdownRemark(
    frontmatter:{ templateKey: { eq: "aboutus-page"}, tab:{eq:true}, tabOrder: {eq: 0}}
    fields: { langKey: {eq: $langKey }}
    ) {
        ...TabInformation
    }
    activeTab: markdownRemark(frontmatter: {templateKey: {eq: "aboutus-page"}, tab: { eq: true}},
      fields: {slug: {eq: $slug}, langKey: {eq: $langKey}}) {
        ...TabInformation
    }
  }
`;
