import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import BlendImage from './../blendImage';
import style from './member.module.scss';

const Member = ({
    name,
    image,
    memberFunction,
    shortText,
    longText,
    ...rest
    }) => {
    const [active, toggleActive] = useState(false);

    return (
        <div className={style.memberContainer} {...rest}>
            <div className={style.memberBody}>
                <div className={style.memberImage}>
                    <BlendImage  src={image} fixed alt="member avatar" />
                </div>
                <div className={style.memberContent}>
                    <h3>{name}</h3>
                    <span>{memberFunction}</span>
                    <span dangerouslySetInnerHTML={{ __html: shortText}} />
                    {active &&
                    <span dangerouslySetInnerHTML={{ __html: longText}} />
                    }
                    <button href="" className={style.buttonMore} onClick={() => toggleActive(!active)}>
                        {!active &&
                            <FormattedMessage id='member.readMoreLabel' defaultMessage='Lee meer +' />
                        }
                        {active &&
                        <FormattedMessage id='member.readLessLabel' defautMessage='Lees minder -' />
                        }

                    </button>
                </div>
            </div>
        </div>
    );
}

export default Member;
