import React, { useContext } from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import { injectIntl } from 'react-intl';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import style from './newsletterForm.module.scss';
import { H2 } from './../../components/headings';
import Button, { ButtonSubmit } from '../../components/buttons/';
import Textfield from '../../components/textfield';
import { LanguageContext } from "../layouts";

const NewsletterFormContent = ({ intl, formResult, onChange, onSubmit, onReset }) => {
    const { langKey } = useContext(LanguageContext);
    const data = useStaticQuery(graphql`
        query getNewsletterForm {
            allMarkdownRemark(filter: {frontmatter: {templateKey: {regex: "/configuration-newsletter/"}}}) {
                edges {
                    node {
                        id
                        frontmatter {
                            title
                            description
                            langKey
                        }
                    }
                }
            }
        }
    `);
    const dataFilteredOnLanguage = data.allMarkdownRemark.edges.filter((item) => item.node.frontmatter.langKey === langKey);

    return (
        <div className={style.newsletterForm}>
            <H2>{dataFilteredOnLanguage[0].node.frontmatter.title}</H2>
            {!formResult &&
                <>
                    <form className={style.form} onSubmit={onSubmit}>
                        <Textfield className={style.textfield} type="text" id="firstName"
                                   placeholder={intl.formatMessage({id: 'newsletter.firstName'})} onChange={onChange}/>
                        <Textfield className={style.textfield} type="text" id="lastName"
                                   placeholder={intl.formatMessage({id: 'newsletter.lastName'})} onChange={onChange}/>
                        <Textfield className={style.textfield} type="text" id="email"
                                   placeholder={intl.formatMessage({id: 'newsletter.email'})} onChange={onChange}/>
                        <ButtonSubmit type="submit">
                            {intl.formatMessage({id: 'newsletter.submitlabel'})}
                        </ButtonSubmit>
                    </form>
                    <p>
                    {dataFilteredOnLanguage[0].node.frontmatter.description}
                    </p>
                </>
            }
            {(formResult !== null && formResult.result === 'success') &&
            <span>{intl.formatMessage({id: 'newsletter.thanks'})}</span>
            }
            {(formResult !== null && formResult.result === 'error') &&
                <>
                    <span className={style.newsletterFormError}>
                        {intl.formatMessage({id: 'newsletter.error'})}
                    </span>
                    <Button type="AHREF" secondary={true} primary={false} onClick={onReset}>Probeer opnieuw</Button>
                </>
            }
        </div>
    );
};

const NewsletterFormContentWithIntl = injectIntl(NewsletterFormContent);

class NewsletterForm extends React.PureComponent {

    state = {
        firstName: '',
        lastName: '',
        email: '',
        formResult: null,
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        const result = await addToMailchimp(this.state.email, { FNAME: this.state.firstName, LNAME: this.state.lastName });
        this.setState({ formResult: result });
    };

    handleChange = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    };

    handleReset = () => {
      this.setState({formResult: null });
    };

    render() {
        return (
            <NewsletterFormContentWithIntl
                onSubmit={this.handleSubmit}
                onChange={this.handleChange}
                onReset={this.handleReset}
                formResult={this.state.formResult}
            />
        )
    }
}

export default NewsletterForm;
