import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import { useForm, usePrevious } from './../../hooks';
import validate from './contactFormValidationRules';
import Button from '../../components/buttons';
import Textfield from './../../components/textfield';
import RadioGroup from './../../components/radioGroup';
import FormControlLabel from './../../components/formControlLabel';
import Radio from './../../components/radio';
import Grid from './../../components/grid';
import Uploadfield from './../../components/uploadfield';
import style from './contactForm.module.scss';
import {Link} from "gatsby";
import Checkbox from "../../components/checkbox";

const encode = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((k)=>{
        formData.append(k, data[k]);
    });
    return formData
}

const ContactForm = ({ intl }) => {
    const [submitState, setSubmitState] = React.useState({ success: false });
    const prevSubmitSuccess = usePrevious(submitState.success);

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        reset,
        resetField,
    } = useForm(login, validate);

    const handleSubmitClick = () => {
        if (window.ga) {
            window.ga('send', 'event', 'contact', 'versturen');
        }
        if (window.dataLayer) {
            window.dataLayer.push({'contactForm': 'send'});
        }
    };

    function login(form) {

        return fetch('/', {
            method: 'POST',
            body: encode({
                'form-name': form.getAttribute('name'),
                ...values
            })
        })
            .then(() => {
                reset();
                setSubmitState({ success: true});
            })
            .catch((error) => {
                setSubmitState({ success: false});
            })
    }

    if (submitState.success && prevSubmitSuccess) {
        // reset
        setSubmitState({ success: false });
    }

    if (submitState.success) {
        return (
            <div className={style.thanks}>{intl.formatMessage({id: 'contactTab.form.thanks'})}</div>
        );
    }

    return (
        <div className={style.contactForm}>
            <form
                id="contactForm"
                name="contact"
                method="POST"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
                noValidate
            >
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="contact" />
                <Grid direction="row" wrap>
                    <Grid.Item xs={12} md={12}>
                        <fieldset className={style.fieldset}>
                            <legend>{intl.formatMessage({id: 'form.gender'})}</legend>
                            <RadioGroup
                                name="gender"
                                value={values.gender || ''}
                                onChange={handleChange}
                            >
                                <FormControlLabel value="male"  control={<Radio />} label={intl.formatMessage({id: 'form.genderMale'})} />
                                <FormControlLabel value="female"  control={<Radio />} label={intl.formatMessage({id: 'form.genderFemale'})} />
                            </RadioGroup>
                        </fieldset>
                    </Grid.Item>
                    <Grid.Item xs={12} md={6}>
                        <Textfield
                            type="text"
                            id="firstName"
                            name="firstName"
                            label={`${intl.formatMessage({id: 'form.firstName'})} (${intl.formatMessage({id: 'mandatoryFormField'})})`}
                            value={values.firstName || ''}
                            outlined
                            fullWidth
                            error={(errors.firstName) ? intl.formatMessage({id: errors.firstName}) : null}
                            onChange={handleChange}
                        />
                    </Grid.Item>
                    <Grid.Item xs={12} md={6}>
                        <Textfield
                            type="text"
                            id="lastName"
                            name="lastName"
                            value={values.lastName || ''}
                            label={`${intl.formatMessage({id: 'form.lastName'})} (${intl.formatMessage({id: 'mandatoryFormField'})})`}
                            outlined
                            fullWidth
                            error={(errors.lastName) ? intl.formatMessage({id: errors.lastName}) : null}
                            onChange={handleChange}
                        />
                    </Grid.Item>
                    <Grid.Item xs={12} md={6}>
                        <Textfield
                            type="text"
                            id="email"
                            name="email"
                            label={`${intl.formatMessage({id: 'form.email'})} (${intl.formatMessage({id: 'mandatoryFormField'})})`}
                            value={values.email || ''}
                            outlined
                            fullWidth
                            error={(errors.email) ? intl.formatMessage({id: errors.email}) : null}
                            onChange={handleChange}
                        />
                    </Grid.Item>
                    <Grid.Item xs={12} md={6}>
                        <Textfield
                            type="text"
                            id="phone"
                            name="phone"
                            value={values.phone || ''}
                            label={intl.formatMessage({id: 'form.phone'})}
                            outlined
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid.Item>
                    <Grid.Item xs={12} md={12}>
                        <Textfield
                            id="comments"
                            name="comments"
                            label={`${intl.formatMessage({id: 'form.comment'})} (${intl.formatMessage({id: 'mandatoryFormField'})})`}
                            value={values.comments || ''}
                            multiline
                            outlined
                            fullWidth
                            rows={8}
                            error={(errors.comments) ? intl.formatMessage({id: errors.comments}) : null}
                            onChange={handleChange}
                        />
                    </Grid.Item>
                    <Grid.Item xs={12} md={12}>
                        <Uploadfield
                            id="containedButtonFile"
                            name="file"
                            label={intl.formatMessage({id: 'contactTab.form.upload.label'})}
                            buttonLabel={intl.formatMessage({id: 'contactTab.form.upload.buttonLabel'})}
                            value={values.file}
                            onChange={handleChange}
                            error={(errors.file) ? intl.formatMessage({id: errors.file}, {fileSize: errors.fileSize}) : null}
                            onReset={() => resetField("file")}
                        />
                    </Grid.Item>
                    <Grid.Item xs={12} md={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.privacy || false}
                                    onChange={handleChange}
                                    name="privacy"
                                    value="yes"
                                />
                            }
                            error={(errors.privacy) ? intl.formatMessage({id: errors.privacy}) : null}
                            label={intl.formatMessage({id: 'form.privacy'})}
                        />
                    </Grid.Item>
                    <Grid.Item xs={12} md={12}>
                        <Button type="submit" onClick={handleSubmitClick}>{intl.formatMessage({id: 'form.submitLabel'})}</Button>
                    </Grid.Item>
                    <Grid.Item xs={12} md={12}>
                        <FormattedMessage
                            id="form.disclaimer"
                            values={{
                                link: <Link key="disclaimerlink" to={intl.formatMessage({id: 'footer.privacyLink.url'})}>{intl.formatMessage({id: 'form.disclaimerLinkLabel'})}</Link>,
                            }}
                        >{(...chunks) => <span className={style.disclaimer}>{chunks}</span>}
                        </FormattedMessage>
                    </Grid.Item>
                </Grid>
            </form>
        </div>
    );
}

const ContactFormWithIntl = injectIntl(ContactForm);

export default ContactFormWithIntl;
