import React from 'react';
import { injectIntl } from 'react-intl';
import WrapperContent from './../../components/wrapperContent';
import ContactForm from './../../containers/contactForm';
import style from './contactTab.module.scss';

const ContactTemplate = ({ intl, address }) => (
    <>
        <WrapperContent color="grey" artifacts={false}>
            <ContactForm />
        </WrapperContent>
        <WrapperContent className={style.address}>
            <h3>{intl.formatMessage({id: 'contactTab.addressLabel'})}</h3>
            <span>{address.contactName}</span>
            <span>{address.contactStreet}</span>
            <span>{address.contactZipcode} {address.contactCity}</span>
            <span>{address.contactCountry}</span>
            <span>{intl.formatMessage({id: 'contactTab.email'})}: <a href={`mailto: ${address.contactEmail}`}>{address.contactEmail}</a></span>
            <span>{intl.formatMessage({id: 'contactTab.phone'})}: <a href={`tel: ${address.contactPhone}`}>{address.contactPhone}</a></span>
        </WrapperContent>
    </>
);

const ContactTemplateWithIntl = injectIntl(ContactTemplate);

const Index = ({ data }) => {
    return (
        <ContactTemplateWithIntl address={data.frontmatter.address} />
    )
};

export default Index;
