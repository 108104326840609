import { bytesToSize } from './../../utils/';

export default function validate(values) {
    let errors = {};

    if (values.file) {
        if (values.file.size > (1024 * 1000)) {
            errors.file = 'form.errors.file';
            errors.fileSize = bytesToSize(values.file.size);
        }
    }

    if (!values.firstName) {
        errors.firstName = 'form.errors.firstName';
    }
    if (!values.lastName) {
        errors.lastName = 'form.errors.lastName';
    }
    if (!values.email) {
        errors.email = 'form.errors.email';
    }

    if (!values.email) {
        errors.email = 'form.errors.email';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = 'form.errors.emailInvalid';
    }

    if (!values.comments) {
        errors.comments = 'form.errors.comments';
    }

    if (!values.privacy) {
        errors.privacy = 'form.errors.privacy';
    }

    return errors;
};
