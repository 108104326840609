import React from 'react';
import PropTypes from 'prop-types';
import style from './textfield.module.scss';

const Textfield = ({
    id,
    className,
    outlined,
    error,
    label,
    fullWidth,
    multiline,
    ...rest
}) => {
    let InputComponent = (multiline) ? 'textarea' : 'input';

    return (
        <>
            {label &&
            <label htmlFor={id} className={[style.label, (error) ? style.error : null].join(' ')}>{label}</label>
            }
            <InputComponent
                id={id}
                className={[
                    style.textfield,
                    (outlined) ? style.outlined : null,
                    (error) ? style.error : null,
                    (fullWidth) ? style.fullWidth : null,
                    (multiline) ? style.multiline: null,
                    className].join(' ')}
                {...rest}
            />
            {error &&
            <span className={style.errorMessage}>{error}</span>
            }
        </>
    );
}

Textfield.propTypes = {
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    outlined: PropTypes.bool,
    error: PropTypes.string,
    label: PropTypes.string,
    fullWidth: PropTypes.bool,
    multiline: PropTypes.bool,
};

export default Textfield;
